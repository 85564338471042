.searchMain {
  width: 100%;
  display: flex;
  justify-content: center;
}

.searchContainer {
  width: 97%;
}

.searchTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.searchText {
  font-size: 16px;
  font-weight: bold;
  color: rgb(50, 50, 50);
}

.searchInputContainer {
  margin-left: 8px;
  position: relative;
}

.searchInput {
  width: 150px;
  height: 22px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 2px;
  padding: 3px;
  box-sizing: border-box;
  text-align: center;
}

.searchBtn {
  width: 37px;
  height: 37px;
  border: 0;
  border-radius: 2px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #006699;
  font-size: 37px;
  background-color: rgb(232, 235, 239);
}

.searchHistoryContainer {
  width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 0px 5px 0px rgb(200, 200, 200);
  background-color: white;
  position: absolute;
  z-index: 3;
  border: 1px solid rgb(200, 200, 200);
  left: 0px;
  /* was 150px */
}

.searchHistoryItem {
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid rgb(200, 200, 200);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 12px;
}

.warningText {
  font-size: 12px;
  color: red;
  margin-top: 20px;
}

.descriptionSearchContainer {
  width: auto;
  margin-left: 30px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(50, 50, 50);
}

.checkbox {
  width: 15px;
  height: 15px;
}

.carouselContainer {
  width: 500px;
  height: auto;
  margin-left: 16px;
  overflow: hidden;
  position: absolute;
  top: 100px;
  right: 7%;
  border-radius: 10px;
}

.carouselContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.carouselContainer .slick-prev,
.carouselContainer .slick-next {
  z-index: 2;
  display: block;
}

@media screen and (max-width: 1200px) {
  .carouselContainer {
    display: none;
  }
}
