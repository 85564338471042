.tableHeader {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  padding-left: 5px;
  border: 1px solid black;
}

.searchTable {
  box-sizing: border-box;
  border-collapse: collapse;
  border: 1px solid black;
  width: 100%;
  margin-top: 15px;
  border: none;
}

.tableRow {
  box-sizing: border-box;
  border: 1px solid black;
  background-color: rgb(212, 236, 246);
  font-size: 12px;
  padding: 8px 10px 8px 3px;
  position: relative;
}

.tableRowSpecial {
  box-sizing: border-box;
  border: 1px solid black;
  background-color: rgb(212, 246, 218);
  font-size: 12px;
  padding: 8px 10px 8px 3px;
  position: relative;
}

.tableRowOptimal {
  box-sizing: border-box;
  border: 1px solid black;
  background-color: rgb(246, 212, 212);
  font-size: 12px;
  padding: 8px 10px 8px 3px;
  position: relative;
}

.tableRowReserved {
  box-sizing: border-box;
  border: 1px solid black;
  background-color: lightyellow;
  font-size: 12px;
  padding: 8px 10px 8px 3px;
  position: relative;
}
.tableRowAvailability {
  box-sizing: border-box;
  border: 1px solid black;
  background-color: rgb(212, 236, 246);
  font-size: 12px;
  padding: 8px 10px 8px 3px;
  text-align: center;
}

.tableRowSpecialAvailability {
  box-sizing: border-box;
  border: 1px solid black;
  background-color: rgb(212, 246, 218);
  font-size: 12px;
  padding: 8px 10px 8px 3px;
  text-align: center;
}

.tableRowOptimalAvailability {
  box-sizing: border-box;
  border: 1px solid black;
  background-color: rgb(246, 212, 212);
  font-size: 12px;
  padding: 8px 10px 8px 3px;
  text-align: center;
}

.tableRowAvailabilityReserved {
  box-sizing: border-box;
  border: 1px solid black;
  background-color: lightyellow;
  font-size: 12px;
  padding: 8px 10px 8px 3px;
  text-align: center;
}

.tableHeaderBrand {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid black;
  width: 125px;
}

.tableHeaderCode {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid black;
  width: 140px;
}

.tableHeaderDesc {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid black;
  width: 515px;
}

.tableHeaderPrice {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid black;
  width: 80px;
}

.tableHeaderAvailability {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  border: 1px solid black;
  width: 50px;
}

.tableHeaderReserve {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid black;
  width: 30px;
}

.tableHeaderSupplierAvailability {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid black;
  width: 70px;
}

.tableHeaderSupplierAvailability {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid black;
  width: 70px;
}

.tableHeaderDelivery {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid black;
  width: 80px;
}

.tableHeaderUpdated {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid black;
  width: 70px;
}

.tableHeaderInfo {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid black;
  width: 50px;
}

.selectedSpareTr {
  font-weight: bold;
  cursor: pointer;
}
.spareTr {
  cursor: pointer;
}

.reservedSpare {
  background-color: lightyellow;
}

.theadSupplierTable {
  visibility: hidden;
  border: 0px;
  height: 0px;
  font-size: 0px;
}

.theadSupplierTableVisible {
  cursor: pointer;
}

.theadSupplierTable th {
  visibility: hidden;
  border: 0px;
}

.tbodySupplierTableSelected {
  background-color: lightyellow;
}

.tableRowSupplierSelected {
  box-sizing: border-box;
  border: 1px solid black;
  font-size: 12px;
  padding: 8px 10px 8px 3px;
  position: relative;
}

.searchTableSupplier {
  box-sizing: border-box;
  border-collapse: collapse;
  border: 1px solid black;
  width: 100%;
  border: none;
  cursor: pointer;
}

.supplierText {
  margin-bottom: 0px;
}

.supplierTextVisibleTH {
  margin-bottom: 20px;
}

.infoIcon {
  width: 25px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  right: 17px;
  top: 4px;
}
.deliveryTimeReliable {
  box-sizing: border-box;
  border: 1px solid black;

  font-size: 12px;
  padding: 8px 3px 8px 3px;
  color: white;
}

.deliveryTimeReliable span {
  box-sizing: border-box;
  height: auto;
  background-color: green;
  padding: 1px 1px;
  width: 100%;
  font-weight: 600;
  display: block;
}

.theadAvailability {
  cursor: pointer;
}

.noReturnIcon {
  font-size: 16px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  right: 21px;
  top: 9px;
  color: rgb(197, 0, 0);
}

.noReturnTextContainer {
  position: absolute;
  z-index: 2;
  background-color: white;
  padding: 5px;
  border: 1px solid black;
  border-radius: 5px;
  top: 35px;
  left: 30px;
}

.spareImageMin {
  width: 20px;
  height: 20px;
  position: absolute;
  cursor: pointer;
  top: 3px;
  right: 17px;
  border: 2px solid white;
}

.copyIconSpan {
  font-size: 16px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  right: 10px;
  top: 8px;
  color: #006699;
}

.copyIconSpan:hover {
  color: rgb(3, 3, 8);
}

.imageWrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 5px;
  padding-top: 2.5px;
  height: 15px;
}

.spareImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.imagePlaceholder {
  width: 15px;
  height: 15px;
}

.imageIcon {
  font-size: 18px;
  cursor: pointer;
  color: #006699;
}

.loadingIcon {
  font-size: 18px;
  color: #006699;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
