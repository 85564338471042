.homePageContainer {
  width: 90%;
}

.companyName {
  font-size: 32px;
  font-weight: bold;
  color: white;
  margin: 0px;
}

.topContainer {
  display: flex;
  flex-direction: column;
}

.clientCodeContainer {
  margin: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  height: 30px;
}

.clientCodeText {
  font-size: 18px;
  color: white;
  margin-right: 10px;
}

.logOutText {
  font-size: 18px;
  color: white;
  margin-left: 10px;
  cursor: pointer;
  font-weight: 250;
  text-decoration: underline;
}

.mainContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  background-color: rgb(240, 240, 240);
  min-width: 1200px;
  min-height: 550px;
  padding: 30px 30px 30px 30px;
}

.nameContainer {
  font-size: 22px;
  color: navy;
}

.contentContainer {
  box-sizing: border-box;
  padding: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  width: 100%;
  min-height: 550px;
  background-color: rgb(232, 235, 239);
  border-radius: 8px;
  border: 1px solid rgb(200, 200, 200);
  padding-bottom: 20px;
}

.menu {
  width: 100%;
  height: 35px;
  background: linear-gradient(to top, #cbddeb, #dfe9f3);
  border: 1px solid rgb(185, 206, 233);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.menuBtn {
  width: auto;
  height: 100%;
  padding: 0px 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(
    to top,
    #d5e3ee 48%,
    #e0e9f2 48%,
    #e0e9f2 100%,
    #d5e3ee 100%
  );
  border: 1px solid rgb(185, 206, 233);
  border-radius: 8px 8px 0px 0px;
  color: rgb(80, 126, 178);
  margin-left: 2px;
  margin-top: 1px;
}

.menuBtn:hover {
  background: linear-gradient(to top, #e0e7ee, #e2eaf0);
  border: 1px solid rgb(162, 184, 214);
}

.menuBtnSelected {
  width: auto;
  height: 100%;
  padding: 0px 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(
    to top,
    #489bd2 48%,
    #3596db 48%,
    #9ab9d8 100%,
    #559ace 100%
  );
  border: 1px solid rgb(185, 206, 233);
  border-radius: 8px 8px 0px 0px;
  color: rgb(238, 241, 244);
  margin-left: 2px;
  margin-top: 1px;
}

.viberContainer {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.clientDataContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 22px;
  align-items: end;
}

.moneyBalanceContainer {
  width: auto;
  height: 100%;
  margin-left: 70px;
  color: navy;
  font-size: 18px;
  white-space: nowrap;
}

.balanceTextContainer {
  font-size: 14px;
  color: rgb(128, 15, 0);
}

.currencyRateContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  align-items: end;
  color: navy;
}

.currencyRateContainer div {
  margin-left: 30px;
}

.currencyRateContainer span {
  margin-right: 5px;
}

.currencyTextContainer {
  font-size: 14px;
  color: rgb(128, 15, 0);
}

.searchContentContainer {
  width: 100%;
  height: 100%;
}
