.modalContainer {
  box-sizing: border-box;
  width: 1200px;
  min-height: 220px;
  max-height: 80vh;
  background-color: rgb(218, 224, 232);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 2px 9px 2px;
  border-radius: 10px;
}

.modalHeader {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background: linear-gradient(to top, #cbddeb, #dfe9f3);
  border: 1px solid rgb(185, 206, 233);
  border-radius: 8px;
  padding: 22px 10px;
  font-weight: bold;
  font-size: 18px;
}

.closeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  color: rgb(117, 168, 231);
  height: 20px;
  width: 20px;
}

.closeIcon:hover {
  color: rgb(84, 148, 226);
}

.modalHR {
  width: 95%;
  margin-top: 27px;
}

.modalHrTop {
  width: 95%;
  margin-top: 13px;
}

.infoText {
  font-size: 12px;
  color: rgb(50, 50, 50);
  width: 30%;
}

.infoBtn {
  background: linear-gradient(to top, #cbddeb, #dfe9f3);
  border: 1px solid rgb(163, 184, 210);
  border-radius: 8px;
  width: 42px;
  height: 32px;
  font-size: 16px;
  color: rgb(70, 127, 197);
  margin-right: 15px;
}

.infoBtn:hover {
  background: linear-gradient(to top, #dfe9f3, #cbddeb);
  color: rgb(84, 148, 226);
  cursor: pointer;
}

.infoBtnContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.arrowBtn {
  border: none;
  color: #006699;
  background-color: transparent;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 8px;
  cursor: pointer;
}
.arrowBtn[disabled] {
  border: none;
  color: rgb(163, 184, 210);
  background-color: transparent;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 8px;
  cursor: not-allowed;
}

.pageNumberInput {
  width: 50px;
  height: 16px;
  border: 1px solid rgb(163, 184, 210);
  border-radius: 5px;
  text-align: center;
}

.historyOrderTable {
  box-sizing: border-box;
  border-collapse: collapse;
  border: 1px solid black;
  width: 95%;
  margin-top: 10px;
}

.historyOrderTable th {
  border: 1px solid black;
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.historyOrderTable td {
  box-sizing: border-box;
  border: 1px solid black;
  text-align: center;
  font-size: 14px;
  padding: 8px;
  position: relative;
  height: 40px;
}

.viewBtn {
  color: #006699;
  background-color: transparent;
  font-size: 22px;
  cursor: pointer;
  border: none;
  position: absolute;
  right: 79px;
  top: 8px;
}

.exportExcelBtn {
  color: #009940;
  background-color: transparent;
  font-size: 22px;
  cursor: pointer;
  border: none;
  position: absolute;
  right: 80px;
  top: 8px;
}

.exportPdfBtn {
  color: #af1400;
  background-color: transparent;
  font-size: 22px;
  cursor: pointer;
  border: none;
  position: absolute;
  right: 155px;
  top: 8px;
}

.docSumContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
  font-weight: 800;
  width: 90%;
}

.returnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0px 5px 0px;
  font-weight: 800;
  width: 95%;
}

.returnBtn {
  background: linear-gradient(to top, #cbddeb, #dfe9f3);
  border: 1px solid rgb(163, 184, 210);
  border-radius: 8px;
  width: 70px;
  height: 32px;
  font-size: 16px;
  color: rgb(56, 107, 170);
  margin-right: 15px;
}

.returnBtn:hover {
  background: linear-gradient(to top, #dfe9f3, #cbddeb);
  color: rgb(84, 148, 226);
  cursor: pointer;
}

.noOrderHistory {
  font-size: 24px;
  color: rgb(50, 50, 50);
  margin-top: 10px;
}

.showOrderDetailsBtn {
  background: linear-gradient(to top, #cbddeb, #dfe9f3);
  border: 1px solid rgb(163, 184, 210);
  border-radius: 8px;
  width: 100px;
  height: 32px;
  font-size: 16px;
  color: rgb(56, 107, 170);
}

.showOrderDetailsBtn:hover {
  background: linear-gradient(to top, #dfe9f3, #cbddeb);
  color: rgb(84, 148, 226);
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #006699;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.toolBarContainer {
  width: 95%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.showDetailsTextContainer {
  margin-right: 10px;
  margin-left: 20px;
  font-weight: bold;
  font-size: 16px;
}

.noResults {
  font-size: 24px;
  color: rgb(50, 50, 50);
  margin-top: 50px;
}

.modalContent {
  box-sizing: border-box;
  overflow-y: auto;
  width: 100%;
  padding: 15px;
}

.imageContainer {
  margin: 15px 0;
  text-align: center;
}

.imageContainer img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}
